import { useEffect, useState } from "react";

const useLocalization = () => {
  const [data, setDate] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(process.env.REACT_APP_IP_API)
      .then((res) => res.json())
      .then((data) => {
        setDate(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setDate(null);
      });
  }, []);

  return { data, loading };
};

export default useLocalization;
