import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

const GlobalStyles = createGlobalStyle`
  ${normalize}
  a {
    text-decoration: none;
  }
  * {
    box-sizing: border-box;
  }
  body {
  }
  html {
    font-size: 62.5%;
  }

`;
export default GlobalStyles;
