import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

import { useAuthContext } from "../../../service/auth/useAuthContext";

export const Home = () => {
  const onChange = (e) => {
    i18n.changeLanguage(e.target.value);
  };
  const { isAuth, user, toggleAuth } = useAuthContext();
  const { t } = useTranslation();

  return (
    <div className="App">
      <header className="App-header">
        <p>isAuth: {isAuth.toString()}</p>
        <p>currentUser: {user?.name || "null"}</p>
        <button
          onClick={() => {
            toggleAuth();
          }}
        >
          toggle auth
        </button>
        <Link to={"sign-in"}>Go to Sign in</Link>
        <Link to={"dashboard"}>Go to Dashboard</Link>
        <p>{t("hello")}</p>
        <select name="language" onChange={onChange}>
          <option value="en">english</option>
          <option value="heb">hebrew</option>
        </select>
      </header>
    </div>
  );
};
