import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";

import useLocalization from "./service/localization/useLocalization";
import useAuth from "./service/auth/useAuth";

import { LocalizationProvider } from "./service/localization/localizationContext";
import { AuthProvider } from "./service/auth/authContext";

import GlobalStyles from "./styles/global-styles";

import SignIn from "./components/pages/auth/SignIn";
import ProtectedRoute from "./components/ProtectedRoute";
import Dashboard from "./components/pages/dashboard/Dashboard";
import { Home } from "./components/pages/home/Home";

function App() {
  const localization = useLocalization();
  const auth = useAuth();

  return (
    <AuthProvider value={auth}>
      <GlobalStyles />
      <LocalizationProvider value={localization}>
        <Router>
          <Route path="/sign-in" component={SignIn} />
          <Route path="/" exact component={Home} />
          <ProtectedRoute
            path="/dashboard"
            exact
            component={Dashboard}
            isAuthenticated={auth.isAuth}
          />
        </Router>
      </LocalizationProvider>
    </AuthProvider>
  );
}

export default App;
