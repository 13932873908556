import { useState } from "react";

const useAuth = () => {
  const [user, setUser] = useState(null);
  const [isAuth, setIsAuth] = useState(false);
  const toggleAuth = () => {
    if (user) {
      setIsAuth(false);
      setUser(null);
    } else {
      setUser({ name: "test user" });
      setIsAuth(true);
    }
  };

  return {
    user,
    isAuth,
    toggleAuth,
  };
};

export default useAuth;
